
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { Getter, Action, Mutation } from "vuex-class";
import { FormInput } from "@/components/forms";
import { bus } from "@/main";
import type { FavouriteActivity, IndexActivityFavoritePayload } from "@/store/modules/activity-favorite.store";
import type { IndexActivityPayload } from "@/store/modules/activity.store";
import { Debounce } from "@/utils/vue-helpers";
import { WorkStatus } from "@/store/modules/activity.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        FormInput,
    },
})
export default class PageActivitiesIndex extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("activity/all") activities!: Activity[];
    @Getter("activity/viewing") viewingActivity!: Activity;
    @Getter("activity/total") total!: number;
    @Getter("activity-favorite/all") favorites!: FavouriteActivity[];
    @Mutation("activity/RESET") resetActivity!: () => void;
    @Action("activity/index") indexActivities!: (payload: IndexActivityPayload) => Activity[];
    @Action("activity-favorite/index") indexFavoriteActivities!: (payload: IndexActivityFavoritePayload) => Promise<ActivityIndex[]>;

    @Prop() parentRoute!: string;

    query: string = "";

    loading = false;

    async mounted() {
        this.loading = true;

        this.resetActivity();

        await this.indexFavoriteActivities({ account_id: Number(this.me.id) });

        await this.indexActivities({ q: { and: { and: { or: { work_status: [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer, WorkStatus.sold, WorkStatus.archived, WorkStatus.onHold] } } } } });

        this.loading = false;
    }

    createActivity() {
        bus.$emit("show-activity-create");
    }

    @Debounce(500)
    async loadMore(after: boolean) {
        this.loading = true;

        await this.indexActivities({ q: { and: { and: { or: { work_status: [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer, WorkStatus.sold, WorkStatus.archived, WorkStatus.onHold] } } } }, after: after ? this.lastAfter : [] });

        this.loading = false;
    }

    @Debounce(300)
    @Watch("query")
    async handleQueryChanged(newValue: string) {
        this.loading = true;

        await this.indexActivities({ q: { and: { and: { or: { work_status: [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer, WorkStatus.sold, WorkStatus.archived, WorkStatus.onHold] } }, or: { name: [`like~0:*${newValue}*`, `match~0:${newValue}`] } } } });

        this.loading = false;
    }

    activitiesScroll(e: Event) {
        if (this.total !== this.activities.length) {
            // @ts-ignore
            const activitiesSidebar: HTMLElement | undefined = this.$refs.activities;
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

            if (activitiesSidebar) {
                if (activitiesSidebar.scrollTop + vh >= activitiesSidebar.getBoundingClientRect().bottom + activitiesSidebar.scrollTop) {
                    this.loadMore(true);
                }
            }
        }
    }

    get lastAfter(): Array<string | number> {
        const total = this.activities.length;
        const last: Activity = this.activities[total - 1];
        if (last && last.sort) {
            return last.sort;
        }

        return [];
    }
}
